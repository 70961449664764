import React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Icon } from 'components/elements';
import { useAuthenticatedRoot } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const useSidebarNavigation = () => {
    const { data: authenticatedRootData, dataUpdatedAt: authenticatedRootDataUpdatedAt } = useAuthenticatedRoot();
    const queryClient = useQueryClient();
    const { t: translate } = useTranslation();

    const sidebarItems = {
        dashboard: {
            key: 'dashboard',
            label: <Link to="/dashboard">{ translate('application.sidebar.home') }</Link>,
            title: translate('application.sidebar.home'),
            icon: <Icon name="Home" />,
            href: '/dashboard',
        },
        bookings: {
            key: 'bookings',
            label: <Link to="/bookings">{ translate('application.sidebar.bookings') }</Link>,
            title: translate('application.sidebar.bookings'),
            icon: <Icon name="Calendar" />,
            href: '/bookings',
        },
        divider: {
            key: 'divider',
            type: 'divider',
        },
        management: {
            key: 'management',
            label: translate('application.sidebar.management.group'),
            title: translate('application.sidebar.management.group'),
            icon: <Icon name="SlidersHorizontal" />,
            disabled: true,
            children: {
                management_leads: {
                    key: 'management_leads',
                    label: <Link to="/management/leads">{ translate('application.sidebar.management.leads') }</Link>,
                    title: translate('application.sidebar.management.leads'),
                    icon: <Icon name="UserCog" />,
                    href: '/management/leads',
                },
                management_members: {
                    key: 'management_members',
                    label: <Link to="/management/members">{ translate('application.sidebar.management.members') }</Link>,
                    title: translate('application.sidebar.management.members'),
                    icon: <Icon name="Users" />,
                    href: '/management/members',
                },
                management_membershiptypes: {
                    key: 'management_membershiptypes',
                    label: <Link to="/management/membership-types">{ translate('application.sidebar.management.membershipTypes') }</Link>,
                    title: translate('application.sidebar.management.membershipTypes'),
                    icon: <Icon name="FileBadge2" />,
                    href: '/management/membership-types',
                }
            },
            type: 'group',
        },
        // management_leads: {
        //     key: 'management_leads',
        //     label: <Link to="/management/leads">{ translate('application.sidebar.management.leads') }</Link>,
        //     icon: <Icon name="UserCog" />,
        // },
        // management_members: {
        //     key: 'management_members',
        //     label: <Link to="/management/members">{ translate('application.sidebar.management.members') }</Link>,
        //     icon: <Icon name="Users" />,
        // },
    };

    const { data, isLoading } = useQuery({
        queryKey: ['sidebar', authenticatedRootDataUpdatedAt],
        queryFn: async () => {
            let items = [];

            let processSidebarItem = (key, value) => {
                if (authenticatedRootData.navigationElements.indexOf(key) === -1) {
                    return;
                }

                if (typeof value.children === 'undefined' || value.children.length === 0) {
                    delete value.children;
                } else {
                    let children = [];

                    Object
                        .entries(value.children || {})
                        .forEach(([childKey, childValue]) => {
                            children.push(processSidebarItem(childKey, childValue));
                        });

                    value = { ...value, children };
                }

                return value;
            };

            Object
                .entries(sidebarItems)
                .forEach(([key, value]) => {
                    items.push(processSidebarItem(key, value));
                });

            await queryClient.removeQueries({ queryKey: ['sidebar'], type: 'inactive' });

            return items.filter((item) => [null, undefined].indexOf(item) === -1);
        },
        enabled: !!authenticatedRootData,
    });

    return {
        data,
        isLoading,
    };
};

export default useSidebarNavigation;
