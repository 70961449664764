import React from 'react';
import { Card, Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';
import { merge } from 'lodash';
import collection from 'config/shapes/collection';
import { Response } from 'services';
import { FloatingTitle } from 'components/wrappers';
import { LabelledText, NoResultsFound } from 'components/elements';

const { Title } = Typography;

const CardList = ({ collection, columns, config, dataSource, filters, isLoading, onPage, renderer, title, ...rest }) => {
    const mergedConfig = merge({}, CardList.defaultProps.config, config);

    const pagination = false === mergedConfig.pagination ?
        false :
        merge(
            {},
            mergedConfig.pagination,
            {
                current: +new URLSearchParams(Response.getLink(collection, 'self')).get('page') || 1,
                pageSize: collection?.limit ?? dataSource?.length ?? 1,
                total: collection?.count || 0,
                onChange: (page, pageSize) => {
                    onPage(
                        Object.assign(
                            {},
                            filters,
                            {page}
                        )
                    );
                }
            }
        );

    const displayTitle = 'undefined' === typeof title
        ? null
        : (
            <Title level={ 3 }>
                { title }
                {
                    mergedConfig.showTitleCount ?
                        '   (' + (collection?.count || dataSource?.length || 0) + ')' :
                        null
                }
            </Title>
        );

    if (isLoading) {
        return <>
            <FloatingTitle>{ displayTitle }</FloatingTitle>
            <Card>
                <Skeleton active />
            </Card>
        </>;
    }

    return <>
        <FloatingTitle>{ displayTitle }</FloatingTitle>
        <div className="CardList">
            { (dataSource || []).map((item, index) => BuildCard(item, index, columns, renderer)) }
            {
                0 === dataSource?.length
                    ? <Card><NoResultsFound /></Card>
                    : null
            }
        </div>
    </>;
};

const BuildCard = (item, index, columns, renderer) => {
    const display = 'function' === typeof renderer
        ? renderer(item, index)
        : BuildItem(item, index, columns);

    const actions = [];
    (columns ?? []).forEach((column) => {
        if ('action' === column.type) {
            actions.push('function' === typeof column.label ? column.label(item) : column.label);
        }
    });

    return <Card key={ index } actions={ actions } >{ display }</Card>;
};

const BuildItem = (item, index, columns) => {
    return (columns || []).map((column) => {
        if ('action' === column.type) {
            return;
        }

        let display = 'function' === typeof column.renderer
            ? column.renderer(item[column.dataIndex], item)
            : item[column.dataIndex];

        return <LabelledText label={ column.label } value={ display } />;
    });
};

CardList.propTypes = {
    collection: collection.propTypes,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    config: PropTypes.shape({
        hasStickyHeaders: PropTypes.bool,
        pagination: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.shape({
                position: PropTypes.arrayOf(PropTypes.string),
            }),
        ]),
        size: PropTypes.string,
        showTitleCount: PropTypes.bool,
    }),
    dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool,
};

CardList.defaultProps = {
    collection: collection.defaultProps,
    config: {
        hasStickyHeaders: true,
        pagination: {
            position: ['bottomCenter'],

        },
        size: 'small',
        showTitleCount: true,
    },
    isLoading: true,
};

export default CardList;
