import { useQuery, useQueryClient } from '@tanstack/react-query';
import { HTTP } from 'services';

const useAuthenticatedRoot = () => {
    const queryClient = useQueryClient();

    const { data, isLoading, error, dataUpdatedAt } = useQuery({
        queryKey: ['authenticatedRoot'],
        queryFn: () => {
            return HTTP
                .get('/')
                .then((res) => res.data);
        },
        enabled: [null, undefined].indexOf(localStorage.getItem('jwt')) === -1,
    });

    const clear = () => {
        queryClient.invalidateQueries({ queryKey: ['authenticatedRoot'] });
    };

    return {
        data,
        isLoading,
        error,
        dataUpdatedAt,

        clear,
    };
};

export default useAuthenticatedRoot;
