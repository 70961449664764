import React from 'react';
import { useTranslation } from 'react-i18next';

const Pending = () => {
    const { t: translate } = useTranslation();

    return (
        <div className="text-center margin-top-medium margin-bottom-medium">
            { translate('application.preauth.descriptions.verify_account_pending') }
            <br />
            <br />
            { translate('application.preauth.descriptions.can_close_window') }
        </div>
    );
};

export default Pending;
