import React, { useState } from 'react';
import PropTypes from 'prop-types';

const StatusPending = (props) => {
    const [ index, setIndex ] = useState(props.min);

    if (props.min >= props.max) {
        return <>{ props.text }{ '.'.repeat(props.min) }</>;
    }

    window.setTimeout(() => setIndex(index+1 >= props.max ? 0 : index+1), 500);

    return <>{ props.text }{ '.'.repeat(index) }</>;
};

StatusPending.propTypes = {
    text: PropTypes.string.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
};

StatusPending.defaultProps = {
    min: 0,
    max: 3,
};

export default StatusPending;
