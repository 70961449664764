import en from 'config/lang/en';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        debug: typeof process === 'undefined' ? true : process?.NODE_ENV === 'dev',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en,
        },
    });

const e = () => {};
export default e;
