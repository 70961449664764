import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useRoot } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { HTTP, Response } from 'services';

const useAuth = () => {
    const queryClient = useQueryClient();
    const { data: rootData } = useRoot();
    const navigate = useNavigate();

    const { mutate: setToken } = useMutation({
        mutationFn: (tokenString) => {
            if (typeof tokenString === "undefined") {
                localStorage.setItem('jwt', undefined);
                HTTP.setToken(undefined);
            } else {
                localStorage.setItem('jwt', tokenString);
                HTTP.setToken(tokenString);
            }
        },
    });

    const { mutate: login } = useMutation({
        mutationFn: async ({ emailAddress, password }) => {
            return await HTTP
                .post(
                    Response.getLink(rootData, 'auth'),
                    {
                        emailAddress,
                        password,
                    },
                );
        },
        onSettled: (res) => {
            if (res) {
                queryClient.resetQueries();
                setToken(res.data.token);

                return true;
            }

            return false;
        },
    });

    const { mutate: register } = useMutation({
        mutationFn: async ({ forename, surname, emailAddress }) => {
            return await HTTP
                .post(
                    Response.getLink(rootData, 'register'),
                    {
                        forename,
                        surname,
                        emailAddress,
                    },
                );
        },
        onSettled: (res) => {
            if (res && res.status === 201) {
                navigate('/pending');
            }
        },
    });

    const { mutate: verify } = useMutation({
        mutationFn: ({ token, password }) => {
            return HTTP
                .post(
                    Response.getLink(rootData, 'verify'),
                    {
                        token,
                        password,
                    },
                );
        },
        onSettled: (res) => {
            if (res) {
                setToken(res.data.tokenString);
                navigate('/dashboard');

                return true;
            }

            return false;
        },
    });

    const { mutate: recoverPassword } = useMutation({
        mutationFn: ({ emailAddress }) => {
            return HTTP
                .post(
                    Response.getLink(rootData, 'forgotten-password'),
                    {
                        emailAddress,
                    },
                );
        },
        onSettled: (res) => {
            if (res) {
                navigate('/forgotten-password-pending');

                return true;
            }

            return false;
        },
    });

    const { mutate: resetPassword } = useMutation({
        mutationFn: ({ token, password }) => {
            return HTTP
                .post(
                    Response.getLink(rootData, 'reset-password'),
                    {
                        token,
                        password,
                    },
                );
        },
        onSettled: (res) => {
            if (res) {
                setToken(res.data.tokenString);
                navigate('/dashboard');

                return true;
            }

            return false;
        },
    });

    const { mutate: upgradeUser } = useMutation({
        mutationFn: ({ token, password }) => {
            return HTTP
                .post(
                    Response.getLink(rootData, 'upgrade'),
                    {
                        token,
                        password,
                    },
                );
        },
        onSettled: (res) => {
            if (res) {
                setToken(res.data.tokenString);

                return true;
            }

            return false;
        },
    });

    const { mutate: logout } = useMutation({
        mutationFn: () => {
            localStorage.clear();
            setToken(undefined);
        },
        onSettled: () => {
            queryClient.removeQueries();
            navigate('/');
        },
    });

    return {
        login,
        logout,
        register,
        verify,
        recoverPassword,
        resetPassword,
        upgradeUser,
    };
};

export default useAuth;
