import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMobileDetection } from 'hooks';

const useDrawer = (drawerName) => {
    const queryClient = useQueryClient();
    const storageItemName = `drawer-${drawerName}`;
    const { isMobile } = useMobileDetection();

    const { data, isLoading, error } = useQuery({
        queryKey: ['drawer', drawerName],
        queryFn: () => {
            let drawerState = localStorage.getItem(storageItemName);

            return null === drawerState ? !isMobile : 'true' === drawerState;
        },
        enabled: typeof isMobile !== 'undefined',
    });

    const toggle = () => {
        localStorage.setItem(storageItemName, [true, 'true'].indexOf(data) !== -1 ? 'false' : 'true');
        queryClient.refetchQueries({ queryKey: ['drawer', drawerName] });
    };

    return {
        data,
        isLoading,
        error,
        toggle,
    };
};

export default useDrawer;
