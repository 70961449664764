import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HTTP, Response } from 'services';

const useMembershipType = (route) => {
    const queryClient = useQueryClient();
    const { t: translate } = useTranslation();
    const navigate = useNavigate();

    const frequencies = [
        { value: 'weekly', label: translate('form.fields.club.membershipType.frequencies.weekly') },
        { value: 'monthly', label: translate('form.fields.club.membershipType.frequencies.monthly') },
        { value: 'biannually', label: translate('form.fields.club.membershipType.frequencies.biannually') },
        { value: 'annually', label: translate('form.fields.club.membershipType.frequencies.annually') },
    ];

    const { data, isLoading } = useQuery({
        queryKey: ['membership-type', route],
        queryFn: async() => {
            return await HTTP
                .get(route)
                .then(res => res && res.data) || []
        },
        enabled: !!route,
        gcTime: 5000,
        staleTime: 5000,
    });

    const { mutate: create, isPending: createPending } = useMutation({
        mutationFn: ({ club, name, frequency, amount, joiningFee, settle }) => {
            return HTTP
                .post(
                    Response.getLink(club, 'create-membership-type'),
                    {
                        name,
                        frequency,
                        amount,
                        joiningFee,
                    },
                );
        },
        onSettled: async (res, error, { settle }, context) => {
            if (res) {
                await queryClient
                    .refetchQueries({ queryKey: ['authenticatedRoot'], type: 'active' })
                    .then(async () => {
                        await queryClient.refetchQueries({ queryKey: ['clubs'] });
                        await queryClient.refetchQueries({ queryKey: ['dashboard'] });
                        await queryClient.resetQueries({ queryKey: ['sidebar'] });

                        // await queryClient.refetchQueries();

                    });

                return settle?.(res);
            }

            return false;
        },
    });

    return {
        data,
        isLoading,

        create,
        createPending,

        frequencies,
    };
};

export default useMembershipType;
