import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input as ANTInput } from 'antd';
import Modifiers from 'components/elements/filter/Modifiers';

const Input = (props) => {
    const { availableModifiers, label, name, placeholder } = props;

    let addonProps = {};
    if (availableModifiers.length > 0) {
        addonProps.addonBefore = <Modifiers availableModifiers={ availableModifiers } fieldName={ name } />;
    }

    return (
        <Form.Item
            label={ label }
            name={ name }
        >
            <ANTInput { ...addonProps }
                placeholder={ placeholder || label }
            />
        </Form.Item>
    );
};

Input.propTypes = {
    availableModifiers: PropTypes.array,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
};

Input.defaultProps = {
    availableModifiers: [],
    placeholder: null,
};

export default Input;
