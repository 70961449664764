import membershipType from 'hooks/form-rules/club/membership-type';
import i18n from 'i18next';

const { t: translate } = i18n;

const e = {
    club: {
        NAME_REQUIRED: {
            required: true,
            type: 'string',
            message: translate('form.rules.club.name.required'),
        },
        ADDRESS_LINE_REQUIRED: {
            required: true,
            type: 'string',
            message: translate('form.rules.club.address.required')
        },
        TOWN_REQUIRED: {
            required: true,
            type: 'string',
            message: translate('form.rules.club.town.required')
        },
        POSTCODE_REQUIRED: {
            required: true,
            type: 'string',
            message: translate('form.rules.club.postcode.required')
        },
        ...membershipType,
    },
};

export default e;
