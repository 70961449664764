import React from "react";
import { Select as ANTSelect, SelectProps as ANTSelectProps } from "antd";

const Select = ({ prefix, children, full, ...rest }) => {
  return (
    <div className={ `SelectWrapper ${full ? 'full' : ''}` }>
      {prefix && <div className="prefix-icon-wrapper">{prefix}</div>}
      <ANTSelect { ...rest }>{children}</ANTSelect>
    </div>
  );
};

export default Select;
