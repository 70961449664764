import React from 'react';
import { useParams } from 'react-router-dom';
import { useMembershipType } from 'hooks';
import { Card } from 'antd';
import { Loading, Page } from 'components/wrappers';

const MembershipTypeDetails = () => {
    const { membershipType } = useParams();
    const { data: membershipTypeData, isLoading: membershipTypeIsLoading } = useMembershipType(atob(membershipType));

    return (
        <Page
            title={ membershipTypeData?.name }
            isLoading={ membershipTypeIsLoading }
        >
                Some data
        </Page>
    );

    return <>Membership Type</>;
};

export default MembershipTypeDetails;
