import React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { HTTP, JWT } from 'services';
import { useAxios, useRoot } from 'hooks';
import PreAuthContainer from 'components/PreAuthContainer';
import PostAuthContainer from 'components/PostAuthContainer';

const AppContainer = () => {
    const queryClient = useQueryClient();
    const { init } = useAxios();
    const token = localStorage.getItem('jwt');
    const { data: rootData } = useRoot();

    init();

    let component = <PreAuthContainer />;
    if (token && JWT.isValid(token)) {
        HTTP.setToken(token);
        component = <PostAuthContainer />;
    }

    return (
        <div className="AppContainer">
            { component }
        </div>
    );
};

export default AppContainer;
