import { useCallback } from 'react';
import { useMobileDetection } from 'hooks';
import { useTranslation } from 'react-i18next';
import {
    FORM_CONFIG,
    FORM_CONFIG_MOBILE,
    FORM_CONFIG_SANS_LABEL,
    FORM_LAYOUT_ACTIONS,
    FORM_LAYOUT_ACTIONS_MOBILE,
} from 'styles/layout/form';

const useFormConfig = () => {
    const { t: translate } = useTranslation();
    const { isMobile } = useMobileDetection();

    const config = useCallback(() => {
        return isMobile ?
            FORM_CONFIG_MOBILE :
            FORM_CONFIG;
    }, [isMobile]);

    const actionsConfig = useCallback(() => {
        return isMobile ?
            FORM_LAYOUT_ACTIONS_MOBILE :
            FORM_LAYOUT_ACTIONS;
    }, [isMobile]);

    const filterConfig = useCallback(() => {
        return FORM_CONFIG_FILTERS;
    });

    const labellessConfig = useCallback(() => {
        return FORM_CONFIG_SANS_LABEL;
    });

    const label = useCallback((text, raw = false) => {
        if (isMobile && 'form.fields.spacer' === text) {
            return null;
        }

        if (null === text || '' === text.trim()) {
            return null;
        }

        return raw ?
            text :
            translate(text);
    }, [isMobile]);

    return {
        config,
        actionsConfig,
        filterConfig,
        labellessConfig,
        label,
    };
};

export default useFormConfig;
