import { useQuery } from '@tanstack/react-query';
import { getSelectorsByUserAgent } from 'react-device-detect';

const useMobileDetection = () => {
    const { data } = useQuery({
        queryKey: ['mobile'],
        queryFn: () => {
            return getSelectorsByUserAgent(window.navigator.userAgent).isMobile;
        },
    });

    return {
        isMobile: data,
    };
};

export default useMobileDetection;
