const e = {
    fields: {
        spacer: ' ',
        active: 'Active',
        select: {
            noneSelected: 'None Selected',
        },


        name: 'Name',
        forename: "Forename",
        surname: "Surname",
        emailAddress: 'Email Address',
        password: 'Password',

        club: {
            name: 'Name',
            address: 'Address',
            addressLine1: 'Address Line 1',
            addressLine2: 'Address Line 2',
            addressLine3: 'Address Line 3',
            town: 'Town',
            county: 'County',
            country: 'Country',
            postcode: 'Postcode',

            membershipType: {
                name: 'Name',
                frequency: 'Frequency',
                frequencies: {
                    weekly: 'Weekly',
                    monthly: 'Monthly',
                    biannually: 'Biannually',
                    annually: 'Annually',
                },
                joiningFee: 'Joining Fee',
                amount: 'Amount',
            },
        },
    },
};

export default e;
