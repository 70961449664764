const e = {
    preauth: {
        links: {
            already_registered: 'Already registered? Login instead.',
            login_instead: 'Login instead.',
            forgotten_password: 'Forgotten Password?',
            register: 'Club Owner? Register here!',
        },
        descriptions: {
            can_close_window: 'You may close this window.',
            forgotten_password_pending: 'If an account exists for that email address, you will receive an email shortly with instructions for how to reset your password.',
            reset_password: 'Please create a new password to gain access to your account. Your password must be at least 8 characters long, and contain at least one lowercase letter, one uppercase letter, and one number.',
            reset_password_token_expired: 'Your password reset token has expired. Please start a new password reset to try again.',
            verify_account: 'Please create a password to continue setup. Your password must be at least 8 characters long, and contain at least one lowercase letter, one uppercase letter, and one number.',
            verify_account_token_expired: 'Your verification token has expired. Please start a password reset to login.',
            verify_account_pending: 'An email has been sent, and you should receive it shortly. Please click the link in the email to verify your email address and continue setting up your account.',
            upgrade_user: 'Click the button below to continue setting up your club.',
            upgrade_user_token_expired: 'Your upgrade token has expired. Please start the registration process again to continue.',
        },
    },
};

export default e;
