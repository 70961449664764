import React from 'react';

const requiredMark = (label, options) => {
    const { required } = options;
    return (
        <>
            { label }
            &nbsp;
            { required && <span style={{ color: "red" }}>*</span> }
        </>
    );
};

const FORM_LAYOUT_FIELDS = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
    labelAlign: 'left',
};

const FORM_LAYOUT_ACTIONS = {
    wrapperCol: {
        span: 24,
    },
    style: {
        textAlign: 'right',
    },
};

const FORM_CONFIG = {
    ...FORM_LAYOUT_FIELDS,
    requiredMark,
    colon: false,
    validateTrigger: 'onSubmit',
    preserve: true,
};

const FORM_CONFIG_SANS_LABEL = {
    ...FORM_CONFIG,
    labelCol: {
        span: 0,
    },
    wrapperCol: {
        span: 24,
    }
};

const FORM_LAYOUT_FIELDS_MOBILE = {};
const FORM_LAYOUT_ACTIONS_MOBILE = {
    style: {
        textAlign: 'right',
    },
};
const FORM_CONFIG_MOBILE = {
    ...FORM_LAYOUT_FIELDS_MOBILE,
    requiredMark,
    colon: false,
    validateTrigger: 'onSubmit',
    preserve: true,
};


export {
    FORM_LAYOUT_FIELDS,
    FORM_LAYOUT_ACTIONS,
    FORM_CONFIG,
    FORM_CONFIG_SANS_LABEL,

    FORM_LAYOUT_FIELDS_MOBILE,
    FORM_LAYOUT_ACTIONS_MOBILE,
    FORM_CONFIG_MOBILE,
};
