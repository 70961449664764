import React from 'react';
import { useMobileDetection } from 'hooks';
import { Flex, Typography } from 'antd';

const { Title } = Typography;

const LabelledText = ({ label, value, ...rest }) => {
    const { isMobile } = useMobileDetection();

    return <Flex vertical={ isMobile } { ...rest }>
        <Title level={ 5 }>{ label }</Title>
        <div>{ value }</div>
    </Flex>;
};

export default LabelledText;
