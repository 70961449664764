import application from 'config/lang/en/application';
import components from 'config/lang/en/components';
import form from 'config/lang/en/form';
import club from 'config/lang/en/club';

const e = {
    translation: {
        ...application,
        ...club,
        ...components,
        ...form,
    },
};
export default e;
