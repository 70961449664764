import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'components/elements';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const AddButton = ({ condition, disabled, disabledMessage, link, float, align, title, tooltipProps, ...rest }) => {
    const navigate = useNavigate();
    const { t: translate } = useTranslation();

    if ([null, false].indexOf(condition) !== -1) {
        return;
    }

    const button = (
        <Button type="primary"
            style={ { 'float': (float || 'none'), 'alignSelf': (align || 'auto') } }
            onClick={ () => navigate('add') }
            disabled={ disabled || link?.disabled }
            title={ (disabled || link?.disabled) ? (disabledMessage || link?.disabledMessage) : title }
            { ...rest }
        >
            <Icon name="Plus" color="#ffffff" />
            { translate('application.buttons.add') }
        </Button>
    );

    if ((disabled || link?.disabled) && (disabledMessage || link?.disabledMessage)) {
        return (
            <Tooltip { ...{ placement: 'bottom', title: (disabledMessage || link?.disabledMessage), ...tooltipProps } }>
                { button }
            </Tooltip>
        );
    }

    return button;
};

export default AddButton;
