import React from 'react';
import { Icon } from 'components/elements';
import { useTranslation } from 'react-i18next';
import { Flex } from "antd";

const NoClubSelected = () =>
{
    const { t: translate } = useTranslation();

    return (
        <Flex justify="center" align="center" vertical className="NoClubSelected">
            <Icon name="Ban" size={ 34 } />
            { translate('application.please_select_a_club') }
        </Flex>
    );
};

export default NoClubSelected;
