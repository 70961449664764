import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useClub } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { HTTP, Response } from 'services';

const usePaymentGateway = () => {
    const queryClient = useQueryClient();
    const { data: clubData } = useClub();
    const navigate = useNavigate();

    const { mutate: skip, isPending: skipIsPending } = useMutation({
        mutationFn: async () => {
            return await HTTP
                .post(Response.getLink(clubData, 'payment-gateway-skip'));
        },
        onSettled: (res) => {
            if (res) {
                queryClient.invalidateQueries({ queryKey: ['authenticatedRoot'] });
                navigate('/dashboard');

                return true;
            }

            return false;
        },
    });

    return {
        skip,
        skipIsPending,
    };
};

export default usePaymentGateway;
