import React from 'react';
import Members from 'components/management/Members';
import MembershipTypes from 'components/management/MembershipTypes';
import { Navigate, Route, Routes } from 'react-router-dom';

const Management = () => {
    return (
        <Routes>
            <Route path="/members/*" element={ <Members /> } />
            <Route path="/membership-types/*" element={ <MembershipTypes /> } />

            <Route path="/*" element={ <Navigate to="/members" /> } />
        </Routes>
    );
};

export default Management;
