import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import AppContainer from 'AppContainer';
import lang from 'config/lang';
import 'styles/index.scss';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

lang();

const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: Infinity, retry: 0, placeholderData: (prev) => prev } } });

const root = createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <QueryClientProvider client={ queryClient }>
                <ConfigProvider
                    theme={
                        {
                            "token": {
                                "wireframe": false,
                                "colorPrimary": "#00a7ae",
                                "colorInfo": "#00a7ae",
                                "colorSuccess": "#a0d911",
                                "colorTextBase": "#000000",
                                "fontSize": 16,
                                "sizeStep": 4,
                                "sizeUnit": 4,
                                "borderRadius": 4,
                            }
                        }
                    }
                >
                    <AppContainer />
                </ConfigProvider>
              <ReactQueryDevtools initialIsOpen={ false } />
            </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(/* console.info */);
