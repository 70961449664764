import React from 'react';
import { Card, Typography } from 'antd';
import { Loading } from 'components/wrappers';

const { Title } = Typography;

const Page = ({ children, isLoading = false, title, ...rest }) => {
    return (
        <Loading isLoading={ isLoading }>
            <div className="Page" { ...rest }>
                {
                    title
                        ? (<Card title={ title }>{ children }</Card>)
                        : children
                }
            </div>
        </Loading>
    );
};

export default Page;
