import React from 'react';
import { useTranslation } from 'react-i18next';

const Currency = ({ value }) => {
    if (value.toString().indexOf('.') === -1) {
        value += '.';
    }

    value += '00';

    return <>
        £{ new Number(value).toFixed(2) }
    </>;
};

export default Currency;
