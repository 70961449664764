import React from 'react';
import { Button, Form, Input, InputNumber, Space } from 'antd';
import { Icon, Select } from 'components/elements';
import { Loading } from 'components/wrappers';
import { useClub, useFormConfig, useFormRules, useMembershipType } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const defaultFormValues = {
    name: "",
    frequency: "monthly",
    amount: "0",
    joiningFee: "0",
};

const ClubSetupMembershipType = () => {
    const { t: translate } = useTranslation();
    const { create, createPending, frequencies } = useMembershipType();
    const formRules = useFormRules();
    const { actionsConfig, config, label } = useFormConfig();
    const { data: clubData } = useClub();
    const { navigate } = useNavigate();

    return (
        <div className="CenteredContainer">
            <div className="Box">
                <div className="text-center margin-top-medium margin-bottom-medium multiline">
                    { translate('club.setup.membershipType') }
                </div>

                <Loading isLoading={ createPending }>
                    <Form { ...config() }
                        initialValues={ defaultFormValues }
                        onFinish={ (values) => create({
                            club: clubData,
                            settle: (res) => navigate('/dashboard'),
                            ...values,
                        }) }
                    >
                        <Form.Item name="name"
                            label={ label('form.fields.club.membershipType.name') }
                            rules={ [formRules.club.membershipType.NAME_REQUIRED] }
                        >
                            <Input
                                prefix={ <Icon name="Hash" /> }
                                placeholder={ translate('form.fields.club.membershipType.name') }
                            />
                        </Form.Item>

                        <Form.Item name="frequency"
                            label={ label('form.fields.club.membershipType.frequency') }
                            rules={ [formRules.club.membershipType.FREQUENCY_REQUIRED] }
                        >
                            <Select
                                prefix={ <Icon name="CalendarClock" /> }
                                placeholder={ translate('form.fields.club.membershipType.frequency') }
                                options={ frequencies }
                                full
                            />
                        </Form.Item>

                        <Form.Item name="joiningFee"
                            label={ label('form.fields.club.membershipType.joiningFee') }
                            rules={ [formRules.club.membershipType.JOINING_FEE_REQUIRED] }
                        >
                            <InputNumber
                                prefix={ <Icon name="PoundSterling" /> }
                                placeholder={ translate('form.fields.club.membershipType.joiningFee') }
                                min="0"
                                step="0.50"
                                stringMode
                            />
                        </Form.Item>

                        <Form.Item name="amount"
                            label={ label('form.fields.club.membershipType.amount') }
                            rules={ [formRules.club.membershipType.AMOUNT_REQUIRED] }
                        >
                            <InputNumber
                                prefix={ <Icon name="PoundSterling" /> }
                                placeholder={ translate('form.fields.club.membershipType.amount') }
                                min="0"
                                step="0.50"
                                stringMode
                            />
                        </Form.Item>

                        <Form.Item { ...actionsConfig() }>
                            <Space direction="vertical">
                                <Button type="primary"
                                    htmlType="submit"
                                    icon={ <Icon name="Save" /> }
                                >
                                    { translate('form.buttons.save') }
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Loading>
            </div>
        </div>
    );
};

export default ClubSetupMembershipType;
