import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedRoot } from 'hooks';
import { HTTP, Response } from 'services';

const useUser = () => {
    const queryClient = useQueryClient();
    const { data: authenticatedRootData } = useAuthenticatedRoot();

    const { data } = useQuery({
        queryKey: ['user', Response.getLink(authenticatedRootData, 'user')],
        queryFn: async () => {
            let response = await HTTP
                .get(Response.getLink(authenticatedRootData, 'user'))
                .then((res) => res.data);

            await queryClient.removeQueries({ queryKey: ['user'], type: 'inactive' });

            return response;
        },
        enabled: typeof authenticatedRootData !== 'undefined',
    });

    return {
        data,
    };
};

export default useUser;
