import React from 'react';
import { useUserMembershipTypes } from 'hooks';
import { Navigate, Route, Routes } from 'react-router-dom';
import MembershipTypeList from 'components/management/membership-types/MembershipTypeList';
import MembershipTypeAdd from 'components/management/membership-types/MembershipTypeAdd';
import MembershipTypeDetails from 'components/management/membership-types/MembershipTypeDetails';

const MembershipTypes = () => {
    const { data: userMembershipTypesData } = useUserMembershipTypes();

    return (
        <Routes>
            <Route path="/list/add" element={ <MembershipTypeAdd /> } />
            <Route path="/list" element={ <MembershipTypeList /> } exact />
            <Route path="/list/:membershipType/edit" Component={ () => <>User Edit</> } exact />
            <Route path="/list/:membershipType" element={ <MembershipTypeDetails /> } />

            <Route path="*" element={ <Navigate to="list" /> } />
        </Routes>
    );

    return <>MembershipTypes</>;
};

export default MembershipTypes;
