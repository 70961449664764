import React from 'react';
import { Button } from 'antd';
import StripeLogo from 'assets/third-party/stripe.svg';
import { useDashboard, usePaymentGateway } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import { Loading } from 'components/wrappers';

const ClubSetupPaymentGateway = () => {
    const { t: translate } = useTranslation();
    const { skip, skipIsPending } = usePaymentGateway();
    const { data: dashboardData, isLoading } = useDashboard();

    if (!dashboardData?.metadata?.setup?.club?.paymentGateway) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <div className="CenteredContainer">
            <div className="Box">
                <h3 className="text-center margin-top-medium margin-bottom-medium multiline">
                    { translate('club.setup.paymentGateway.title') }
                </h3>

                <div className="text-center margin-top-medium margin-bottom-medium multiline">
                    { translate('club.setup.paymentGateway.subtitle')}
                </div>

                <Loading isLoading={ skipIsPending }>
                    <Link className="PaymentButton"
                        to="/setup/club/payment-gateway/stripe"
                    >
                        <img src={ StripeLogo }  />
                    </Link>

                    <Button className="margin-top-large"
                        type="link"
                        onClick={ () => skip() }
                    >
                        { translate('club.setup.paymentGateway.notRequired') }
                    </Button>
                </Loading>
            </div>
        </div>
    );
};

export default ClubSetupPaymentGateway;
