import axios from 'axios';
import { JWT } from 'services';

class HTTP {
    token;
    queryClient;

    static setToken(token) {
        HTTP.token = token;
    }

    static executeGet(url, params, headers) {
        const route = HTTP.getUrl(url);

        return axios
            .get(route, {
                headers,
                params: params || {},
                validateStatus: (status) => {
                    return [200].indexOf(status) !== -1;
                },
            })
            .catch(HTTP.handleError);
    }

    static unauthorizedGet(url, params = "") {
        return HTTP.executeGet(url, params);
    }

    static get(url, params = "", headers = {}) {
        return HTTP.executeGet(url, params, HTTP.getHeaders(headers));
    }

    static post(url, data, headers = {}) {
        // data = HTTP.normaliseData(data);
        // console.log(
        //     'POST',
        //     url,
        //     HTTP.getUrl(url),
        // );

        return axios
            .post(HTTP.getUrl(url), data, {
                headers: HTTP.getHeaders(headers),
                validateStatus: (status) => {
                    return [
                        200,
                        201,
                        202,
                        204,
                    ].indexOf(status) !== -1;
                },
            }).catch(HTTP.handleError);
    }

    static getUrl(url) {
        return process.env.REACT_APP_BASE_URL + url;
    }

    static getHeaders(headers = {}) {
        if (!headers.Authorization) {
            if (HTTP.token && JWT.isValid(HTTP.token)) {
                headers.Authorization = 'Bearer ' + HTTP.token;
            }
        }

        return headers;
    }
}

export default HTTP;
