import React from 'react';
import { useFilters, useFormConfig, useUserMembers } from 'hooks';
import { Filters } from 'components/elements';

const MemberList = () => {
    const { data: userMembersData, isLoading: userMembersIsLoading, updateFilters } = useUserMembers();
    const { label } = useFormConfig();
    const { data: filterData } = useFilters('user-members');

    return (
        <div className="MemberList">
            <Filters onSubmit={ updateFilters }>
                <Filters.Input label={ label('form.fields.name') } name="name" availableModifiers={ ['contains'] } />
            </Filters>
        </div>
    );
};

export default MemberList;
