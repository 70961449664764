import { useQuery } from '@tanstack/react-query';
import { HTTP } from 'services';

const useRoot = () => {
    const { data, isLoading, error } = useQuery({
        queryKey: ['root'],
        queryFn: async () => {
            return await HTTP
                .unauthorizedGet('/')
                .then((res) => res.data);
        },
    });

    return {
        data,
        isLoading,
        error,
    };
};

export default useRoot;
