const e = {
    buttons: {
        login: 'Login',
        register: 'Register',
        verify_account: 'Verify Account',
        upgrade_user: 'Upgrade Account',
        recover_password: 'Recover Password',
        reset_password: 'Reset Password',

        save: 'Save',
        filter: 'Filter',
    },
};

export default e;
