import rules from 'config/lang/en/form/rules';
import fields from 'config/lang/en/form/fields';
import buttons from 'config/lang/en/form/buttons';

const e = {
    form: {
        ...rules,
        ...fields,
        ...buttons,
    },
};

export default e;
