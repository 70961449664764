import PropTypes from 'prop-types';

const propTypes = PropTypes.shape({
    _links: PropTypes.shape({
        self: PropTypes.shape({
            href: PropTypes.string,
        }).isRequired,
    }).isRequired,
    count: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
});

const defaultProps = {
    _links: {
        self: {
            href: null,
        },
    },
    count: 0,
    limit: 0,
    page: 0,
    pages: 0,
};

export {
    propTypes,
    defaultProps,
};

export default {
    propTypes,
    defaultProps,
};
