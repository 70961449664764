import i18n from 'i18next';

const { t: translate } = i18n;

const e = {
    NAME_REQUIRED: {
        required: true,
        type: 'string',
        message: translate('form.rules.name.required'),
    },
    FORENAME_REQUIRED: {
        required: true,
        type: 'string',
        message: translate('form.rules.forename.required'),
    },
    FORENAME_LENGTH: {
        min: 2,
        message: translate('form.rules.forename.minLength'),
    },
    SURNAME_REQUIRED: {
        required: true,
        type: 'string',
        message: translate('form.rules.surname.required'),
    },
    SURNAME_LENGTH: {
        min: 2,
        message: translate('form.rules.surname.minLength'),
    },
    EMAIL_REQUIRED: {
        required: true,
        type: 'email',
        message: translate('form.rules.emailAddress.required'),
    },
    PASSWORD_REQUIRED: {
        required: true,
        message: translate('form.rules.password.required'),
    },
    PASSWORD_LENGTH: {
        min: 8,
        message: translate('form.rules.password.length'),
    },
    PASSWORD_COMPLEXITY: {
        validator: (_, value) => {
            if (
                false === new RegExp(/[a-z]+/).test(value) ||
                false === new RegExp(/[A-Z]+/).test(value) ||
                false === new RegExp(/[0-9]+/).test(value)
            ) {
                return Promise.reject(translate('form.rules.password.complexity'));
            }

            return Promise.resolve();
        },
        message: translate('form.rules.password.complexity'),
    },
};

export default e;
