import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'components/elements';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const ViewButton = ({ route, ...rest }) => {
    const navigate = useNavigate();
    const { t: translate } = useTranslation();

    return (
        <Button type="primary" onClick={ () => navigate(route) } { ...rest }>
            <Icon name="Eye" color="#ffffff" />
            { translate('application.buttons.view') }
        </Button>
    );
};

export default ViewButton;
