import React from 'react';
import { Drawer, Layout, Menu } from 'antd';
import ClubSetup from 'components/ClubSetup';
import ClubSetupMembershipType from 'components/ClubSetupMembershipType';
import ClubSetupPaymentGateway from 'components/ClubSetupPaymentGateway';
import ClubSetupPaymentGatewayStripe from 'components/ClubSetupPaymentGatewayStripe';
import Dashboard from 'components/Dashboard';
import Header from 'components/layout/Header';
import Management from 'components/Management';
import { useDrawer, useMobileDetection, useSidebarNavigation  } from 'hooks';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

const { Header: ANTHeader, Footer, Sider, Content } = Layout;

const PostAuthContainer = () => {
    const { isMobile } = useMobileDetection();
    const { data: drawerState, toggle } = useDrawer('application');
    const { data: sidebarNavigationData } = useSidebarNavigation();
    const location = useLocation();

    let selectedKey = 'dashboard';
    const determineSelectedKey = (group) => {
        group
            .forEach((value) => {
                if (location.pathname.match(new RegExp(`^${value.href}`))) {
                    selectedKey = value.key;
                }

                if (value.children) {
                    determineSelectedKey(value.children);
                }
            });
    };

    determineSelectedKey(sidebarNavigationData || []);

    return (
        <Layout className="PostAuthContainer">
            <ANTHeader>
                <Header />
            </ANTHeader>
            <Layout>
                {
                    isMobile ? (
                        <Drawer
                            width={ 200 }
                            placement="left"
                            closable={ false }
                            onClose={ toggle }
                            open={ drawerState }
                        >
                            <Menu
                                defaultSelectedKeys={ ['dashboard'] }
                                selectedKeys={ [ selectedKey ] }
                                defaultOpenKeys={ ['management'] }
                                mode="inline"
                                items={ sidebarNavigationData }
                            />
                        </Drawer>
                    ) : (
                        <Sider
                            theme="light"
                            trigger={ null }
                            collapsible
                            collapsedWidth="80"
                            collapsed={ !drawerState }
                            onCollapse={ toggle }
                        >
                            <Menu
                                defaultSelectedKeys={ ['dashboard'] }
                                selectedKeys={ [ selectedKey ] }
                                defaultOpenKeys={ ['management'] }
                                mode="inline"
                                items={ sidebarNavigationData }
                            />
                        </Sider>
                    )
                }

                <Content>
                    <Routes>
                        <Route path="/dashboard" element={ <Dashboard /> } exact />

                        <Route path="/setup/club" element={ <ClubSetup /> } exact />
                        <Route path="/setup/club/membership-type" element={ <ClubSetupMembershipType /> } exact />
                        <Route path="/setup/club/payment-gateway" element={ <ClubSetupPaymentGateway /> } exact />
                        <Route path="/setup/club/payment-gateway/stripe" element={ <ClubSetupPaymentGatewayStripe /> } exact />

                        <Route path="/management/*" element={ <Management /> } />

                        <Route path="*" element={ <Navigate to="/dashboard" /> } />
                    </Routes>
                </Content>
            </Layout>
            <Footer className="Footer">
                Footer
            </Footer>
        </Layout>
    );
};

export default PostAuthContainer;


// const PostAuthContainer = () => {
//     const { isMobile } = useMobileDetection();
//     const { data: drawerState, toggle } = useDrawer('application');

//     return (
//         <Layout className="PostAuthContainer" style={ layoutStyle }>
//             {
//                 isMobile ? (
//                     <Drawer
//                         width="200"
//                         placement="left"
//                         closable={ false }
//                         onClose={ toggle }
//                         open={ drawerState }
//                     >
//                         Menu Content
//                     </Drawer>
//                 ) : (
//                     <Sider
//                         collapsible
//                         collapsedWidth="80"
//                         collapsed={ !drawerState }
//                         onCollapse={ toggle }
//                     >
//                         Sider
//                     </Sider>
//                 )
//             }
//             <Layout style={ innerLayoutStyle }>
//                 <Header style={ headerStyle }>
//                     Header
//                 </Header>
//                 <Content style={ contentStyle }>
//                     Main Content
//                 </Content>
//                 <Footer style={ footerStyle }>
//                     Footer
//                 </Footer>
//             </Layout>
//         </Layout>
//     );
// };
