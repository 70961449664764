import React from 'react';
import { useUserMembers } from 'hooks';
import { Navigate, Route, Routes } from 'react-router-dom';
import MemberList from 'components/management/members/MemberList';

const Members = () => {
    const { data: userMembersData } = useUserMembers();

    return (
        <Routes>
            <Route path="/add" Component={ () => <>Add</> } />
            <Route path="/list" element={ <MemberList /> } />
            <Route path="/:userId/edit" Component={ () => <>User Edit</> } exact />
            <Route path="/:userId" Component={ () => <>User</> } />

            <Route path="*" element={ <Navigate to="list" /> } />
        </Routes>
    );

    return <>Members</>;
};

export default Members;
