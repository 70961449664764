import React, { useMemo } from 'react';
import { Button, Form, Input, InputNumber, Space } from 'antd';
import { Icon, NoClubSelected, Select } from 'components/elements';
import { useClub, useFormConfig, useFormRules, useMembershipType } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Loading, Page } from 'components/wrappers';
import { useNavigate } from 'react-router-dom';
import { Response } from 'services';

const defaultFormValues = {
    name: "",
    frequency: "monthly",
    amount: "0",
    joiningFee: "0",
};

const MembershipTypeAdd = () => {
    const { t: translate } = useTranslation();
    const { create, createPending, frequencies } = useMembershipType();
    const formRules = useFormRules();
    const { actionsConfig, config, label } = useFormConfig();
    const { data: clubData } = useClub();
    const navigate = useNavigate();

    if (typeof clubData === 'undefined') {
        return <NoClubSelected />;
    }

    return (
        <Page title={ translate('application.club.membershipType.add.title') }>
            <Loading isLoading={ createPending }>
                <Form { ...config() }
                    initialValues={ defaultFormValues }
                    onFinish={ (values) => create({
                        club: clubData,
                        settle: (res) => navigate(`/management/membership-types/list/${ Response.getBase64Link(res?.data, 'self') }`),
                        ...values,
                    }) }
                >
                    <Form.Item name="name"
                        label={ label('form.fields.club.membershipType.name') }
                        rules={ [
                            formRules.club.membershipType.NAME_REQUIRED,
                            formRules.club.membershipType.NAME_LENGTH,
                        ] }
                    >
                        <Input
                            prefix={ <Icon name="Hash" /> }
                            placeholder={ translate('form.fields.club.membershipType.name') }
                        />
                    </Form.Item>

                    <Form.Item name="frequency"
                        label={ label('form.fields.club.membershipType.frequency') }
                        rules={ [formRules.club.membershipType.FREQUENCY_REQUIRED] }
                    >
                        <Select
                            prefix={ <Icon name="CalendarClock" /> }
                            placeholder={ translate('form.fields.club.membershipType.frequency') }
                            options={ frequencies }
                            full
                        />
                    </Form.Item>

                    <Form.Item name="joiningFee"
                        label={ label('form.fields.club.membershipType.joiningFee') }
                        rules={ [formRules.club.membershipType.JOINING_FEE_REQUIRED] }
                    >
                        <InputNumber
                            prefix={ <Icon name="PoundSterling" /> }
                            placeholder={ translate('form.fields.club.membershipType.joiningFee') }
                            min="0"
                            step="0.50"
                            stringMode
                        />
                    </Form.Item>

                    <Form.Item name="amount"
                        label={ label('form.fields.club.membershipType.amount') }
                        rules={ [formRules.club.membershipType.AMOUNT_REQUIRED] }
                    >
                        <InputNumber
                            prefix={ <Icon name="PoundSterling" /> }
                            placeholder={ translate('form.fields.club.membershipType.amount') }
                            min="0"
                            step="0.50"
                            stringMode
                        />
                    </Form.Item>

                    <Form.Item { ...actionsConfig() }>
                        <Space direction="vertical">
                            <Button type="primary"
                                htmlType="submit"
                                icon={ <Icon name="Save" /> }
                            >
                                { translate('form.buttons.save') }
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Loading>
        </Page>
    );
};

export default MembershipTypeAdd;
