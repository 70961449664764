import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select as ANTSelect } from 'antd';
import Modifiers from 'components/elements/filter/Modifiers';
import { useTranslation } from 'react-i18next';

const { Option } = ANTSelect;

const defaultRenderer = (option, index) => {
    return (
        <ANTSelect.Option value={ option.value }
            label={ option.label }
            key={ option.value }
        >
            { option.label }
        </ANTSelect.Option>
    );
};

const Select = ({ availableModifiers, label, mode, name, options, optionRenderer, placeholder, showNoneSelectedOption }) => {
    const { t: translate } = useTranslation();

    const renderer = 'function' === typeof optionRenderer ? optionRenderer : defaultRenderer;
    const renderedOptions = options.map((option, index) => renderer(option, index));

    if (showNoneSelectedOption) {
        renderedOptions.unshift(renderer({value: null, label: translate('form.fields.select.noneSelected')}, -1));
    }

    let addonProps={};
    if (availableModifiers.length > 0) {
        addonProps.addonBefore = <Modifiers availableModifiers={ availableModifiers } fieldName={ name } />;
    }

    return (
        <Form.Item
            label={ label }
            name={ name }
        >
            <ANTSelect { ...addonProps }
                mode={ mode }
                placeholder={ placeholder || label }
                defaultValue={ null }
            >
                { renderedOptions }
            </ANTSelect>
        </Form.Item>
    );
};

Select.propTypes = {
    availableModifiers: PropTypes.array,
    label: PropTypes.string.isRequired,
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    optionRenderer: PropTypes.func,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.object,
    ]),
    showNoneSelectedOption: PropTypes.bool,
};

Select.defaultProps = {
    availableModifiers: [],
    mode: null,
    showNoneSelectedOption: true,
};

export default Select;
