const useWindow = () => {
    const open = async (
        url,
        handler,
        windowName,
    ) => {
        const width = 600,
            height = 500,
            top = (window.screen.availTop || 0) + (window.screen.availHeight / 2 - height / 2),
            left = (window.screen.availLeft || 0) + (window.screen.availWidth / 2 - width / 2);

        let isHandlingPromise;
        const messageHandler = (e) => {
            const handled = handler(e);
            if (handled) {
                isHandlingPromise = handled;
            }
        };

        window.addEventListener('message', messageHandler, { capture: false, passive: true });
        try {
            return await new Promise((res, rej) => {
                const win = window.open(
                    url,
                    windowName,
                    `menubar=no, location=no, resizable=yes, scrollbars=no, status=no, width=${width}, height=${height}, top=${top}, left=${left}`,
                );

                if (!win) {
                    return rej(new Error('Unable to open a new window.'));
                }

                const pollClosed = () => {
                    if (win?.closed) {
                        setTimeout(async () => {
                            if (isHandlingPromise) {
                                return res(await isHandlingPromise);
                            }

                            return res(undefined);
                        }, 200);
                    }
                };
                pollClosed();
            });
        } finally {
            window.removeEventListener('message', messageHandler);
        }
    };

    return {
        open,
    };
};

export default useWindow;
