import React from 'react';
import { useAuth, useClub, useClubs, useDrawer } from 'hooks';
import LogoStamp from 'assets/Stamp - White.png';
import { Button } from 'antd';
import { Icon, Select } from 'components/elements';
import { Response } from 'services';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const { data } = useClubs();
    const { data: drawerState, toggle } = useDrawer('application');
    const { logout } = useAuth();
    const { data: clubData, clear, select } = useClub();
    const { t: translate } = useTranslation();

    let ClubSelect = null;
    if (data?.clubs?.length > 0) {
        ClubSelect = (
            <Select
                prefix={ <Icon name="Building" /> }
                options={ data?.clubs }
                fieldNames={ { label: 'name', value: 'id' } }
                showSearch={ true }
                onSelect={ (value, option) => { select(Response.getLink(option, 'self')); } }
                value={ clubData?.name || translate('application.header.club.select') }
                allowClear={ true }
                onClear={ clear }
            />
        );
    }

    return (
        <div className="Header">
            <div>
                <img src={ LogoStamp } />

                <Button
                    type="link"
                    icon={ <Icon name="Menu" size="34" color="#2f2f2f" /> }
                    onClick={ () => { toggle(); } }
                />
            </div>

            <div>
                { ClubSelect }

                <Button
                    type="link"
                    icon={ <Icon name="LogOut" size="34" color="#2f2f2f" /> }
                    onClick={ () => { logout(); } }
                />
            </div>
        </div>
    );
};

export default Header;
