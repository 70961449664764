const e = {
    sidebar: {
        home: 'Home',
        bookings: 'Bookings',
        management: {
            group: 'Management',
            leads: 'Leads',
            members: 'Members',
            membershipTypes: 'Membership Types',
        }
    },
};

export default e;
