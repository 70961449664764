import { useQuery } from '@tanstack/react-query';
import { useClub } from 'hooks';
import { HTTP, Response } from 'services';

const useStripeOnboarding = () => {
    const { data: clubData } = useClub();

    const { data } = useQuery({
        queryKey: ['stripe', 'onboarding', Response.getLink(clubData, 'self')],
        queryFn: async () => {
            return await HTTP
                .get(Response.getLink(clubData, 'stripe-onboarding'))
                .then((res) => res?.data);
        },
        enabled: !!clubData,
    });

    const validate = async () => {
        let res = await HTTP
            .get(Response.getLink(clubData, 'stripe-onboarding-validate'))
            .then((res) => res?.data?.isValidated);

        return res;
    };

    return {
        data,

        validate,
    };
};

export default useStripeOnboarding;
