import buttons from 'config/lang/en/application/buttons';
import header from 'config/lang/en/application/header';
import results from 'config/lang/en/application/results';
import sidebar from 'config/lang/en/application/sidebar';
import preauth from 'config/lang/en/application/preauth';

const e = {
    application: {
        ...buttons,
        ...header,
        ...results,
        ...preauth,
        ...sidebar,


        club: {
            membershipTypes: 'Membership Types',
            membershipType: {
                add: {
                    title: 'Add Membership Type',
                },
                frequency: {
                    weekly: 'Weekly',
                    monthly: 'Monthly',
                    annually: 'Annually',
                },
            },
        },
        name: 'Club Flow',

        please_select_a_club: 'Please select a Club',
        invalid_credentials: 'Invalid credentials.',
        filters: 'Filters',

        filter: {
            contains: 'Contains',
            does_not_contain: 'Does Not Contain',
            begins_with: 'Begins With',
            ends_with: 'Ends With',
            is_equals: 'Is Equal To',
            is_not_equal: 'Is Not Equal To',
        },
    },
    name: 'Club Flow',
};

export default e;
