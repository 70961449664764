const e = {
    club: {
        setup: {
            initialise: 'Let\'s get you started.\nPlease enter your club details below.',
            membershipType: 'Please enter your first membership type.',
            paymentGateway: {
                title: 'Select your preferred payment gateway.',
                subtitle: 'It is not possible to create members without having a payment gateway account set up, unless you do not require payment for all of your memberships.\n\nThis can be changed at a later date if you just want to try the software for now.',
                notRequired: 'Skip',
                stripe: {
                    fetchingRedirectionUrl: 'Fetching Stripe URL.',
                    waitingForResponse: 'Waiting for you to complete the connection.',
                    error: 'There was an error.',
                    reload: 'Please reload the page to try again.',
                    validating: 'Validating account setup status.',
                    validationError: 'There was an error validating your account.',
                    validated: 'Looks good!',
                    alreadyValidated: 'You\'re already set up!',
                }
            }
        },
    },
};

export default e;
