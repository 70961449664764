import React from 'react';
import { Button, Form, Input, Space } from 'antd';
import { Icon, Select } from 'components/elements';
import { Loading } from 'components/wrappers';
import { useClub, useFormConfig, useFormRules } from 'hooks';
import { useTranslation } from 'react-i18next';

const defaultFormValues = {
    name: "",
    address: {
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        town: "",
        county: "",
        country: "GB",
        postcode: "",
    },
};

const ClubSetup = () => {
    const { t: translate } = useTranslation();
    const { create, createPending } = useClub();
    const formRules = useFormRules();
    const { actionsConfig, config, label } = useFormConfig();

    return (
        <div className="CenteredContainer">
            <div className="Box">
                <h3 className="text-center margin-top-medium margin-bottom-medium multiline">
                    { translate('club.setup.initialise') }
                </h3>

                <Loading isLoading={ createPending }>
                    <Form { ...config() }
                        initialValues={ defaultFormValues }
                        onFinish={ (values) => create({ ...values }) }
                    >
                        <Form.Item name="name"
                            label={ label('form.fields.club.name') }
                            rules={ [formRules.club.NAME_REQUIRED] }
                        >
                            <Input prefix={ <Icon name="Building" /> }
                                placeholder={ translate('form.fields.club.name') }
                            />
                        </Form.Item>

                        <Form.Item name={ ['address', 'addressLine1'] }
                            label={ label('form.fields.club.address') }
                            rules={ [formRules.club.ADDRESS_LINE_REQUIRED] }
                        >
                            <Input
                                prefix={ <Icon name="Map" /> }
                                placeholder={ translate('form.fields.club.addressLine1') }
                            />
                        </Form.Item>

                        <Form.Item name={ ['address', 'addressLine2'] } label={ label('form.fields.spacer') }>
                            <Input
                                prefix={ <Icon name="Map" /> }
                                placeholder={ translate('form.fields.club.addressLine2') }
                            />
                        </Form.Item>

                        <Form.Item name={ ['address', 'addressLine3'] } label={ label('form.fields.spacer') }>
                            <Input
                                prefix={ <Icon name="Map" /> }
                                placeholder={ translate('form.fields.club.addressLine3') }
                            />
                        </Form.Item>

                        <Form.Item name={ ['address', 'town'] }
                            label={ label('form.fields.club.town') }
                            rules={ [formRules.club.TOWN_REQUIRED] }
                        >
                            <Input
                                prefix={ <Icon name="Map" /> }
                                placeholder={ translate('form.fields.club.town') }
                            />
                        </Form.Item>

                        <Form.Item name={ ['address', 'county'] }
                            label={ label('form.fields.club.county') }
                        >
                            <Input
                                prefix={ <Icon name="Map" /> }
                                placeholder={ translate('form.fields.club.county') }
                            />
                        </Form.Item>

                        <Form.Item name={ ['address', 'postcode'] }
                            label={ label('form.fields.club.postcode') }
                            rules={ [formRules.club.POSTCODE_REQUIRED] }
                        >
                            <Input
                                prefix={ <Icon name="Map" /> }
                                placeholder={ translate('form.fields.club.postcode') }
                            />
                        </Form.Item>

                        <Form.Item name={ ['address', 'country'] }
                            label={ label('form.fields.club.country') }
                            rules={ [formRules.club.POSTCODE_REQUIRED] }
                        >
                            <Select full
                                prefix={ <Icon name="Map" /> }
                                placeholder={ translate('form.fields.club.country') }
                                options={[{ value: 'GB', label: 'United Kingdom' }]}
                            />
                        </Form.Item>

                        <Form.Item { ...actionsConfig() }>
                            <Space direction="vertical">
                                <Button type="primary"
                                    htmlType="submit"
                                    icon={ <Icon name="Save" /> }
                                >
                                    { translate('form.buttons.save') }
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Loading>
            </div>
        </div>
    );
};

export default ClubSetup;
