import React from 'react';
import { Loading } from 'components/wrappers';
import { useDashboard } from 'hooks';
import { Navigate } from 'react-router-dom';

const Dashboard = () => {
    const { data: dashboardData, isLoading } = useDashboard();

    if (dashboardData?.metadata?.setup?.club?.initialise) {
        return <Navigate to="/setup/club" />;
    } else if (dashboardData?.metadata?.setup?.club?.membershipTypes) {
        return <Navigate to="/setup/club/membership-type" />;
    } else if (dashboardData?.metadata?.setup?.club?.paymentGateway) {
        return <Navigate to="/setup/club/payment-gateway" />;
    }

    return (
        <Loading isLoading={ isLoading }>
            <div className="Dashboard">
                Dashboard
            </div>
        </Loading>
    );
};

export default Dashboard;
