import React from 'react';
import { Button, Form, Input, Space } from 'antd';
import { Icon } from 'components/elements';
import { useAuth, useFormConfig, useFormRules } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HTTP } from 'services';
import axios from 'axios';

const defaultFormValues = {
    emailAddress: '',
    password: '',
};

const Login = () => {
    const { t: translate } = useTranslation();
    const { actionsConfig, config, label } = useFormConfig();
    const formRules = useFormRules();
    const { login } = useAuth();

    return (
        <Form { ...config() }
            initialValues={ defaultFormValues }
            onFinish={ (values) => login({ ...values }) }
        >
            <Form.Item name="emailAddress"
                label={ label('form.fields.emailAddress') }
                rules={ [formRules.EMAIL_REQUIRED] }
            >
                <Input prefix={ <Icon name="AtSign" /> }
                    autoComplete="username"
                    placeholder={ translate('form.fields.emailAddress') }
                    data-testid="login-emailAddress"
                />
            </Form.Item>

            <Form.Item name="password"
                label={ label('form.fields.password') }
                rules={ [formRules.PASSWORD_REQUIRED, formRules.PASSWORD_LENGTH, formRules.PASSWORD_COMPLEXITY] }
            >
                <Input.Password prefix={ <Icon name="Lock" /> }
                    autoComplete="current-password"
                    placeholder={ translate('form.fields.password') }
                    data-testid="login-password"
                />
            </Form.Item>

            <Form.Item { ...actionsConfig() }>
                <Space direction="vertical">
                    <Button type="primary"
                        htmlType="submit"
                        icon={ <Icon name="Send" /> }
                        data-testid="login-button"
                    >
                        { translate('form.buttons.login') }
                    </Button>

                    <Link data-testid="login-link-forgottenPassword" to="/forgotten-password">{ translate('application.preauth.links.forgotten_password') }</Link>
                    <Link data-testid="login-link-register" to="/register">{ translate('application.preauth.links.register') }</Link>
                </Space>
            </Form.Item>
        </Form>
    );
};

export default Login;
