import React from 'react';
// import { Icon } from 'components/elements';
import { Spin } from 'antd';

const LoadingIndicator = (props) =>
{
    const { inline, size, key } = props;

    return (
        <div className={ 'LoadingIndicator' + (inline ? ' inline-block' : '') }
            data-size={ size }
            key={ key || Math.ceil(Math.random() * 999999999) }>
            <Spin size={ size || 'small' } />
        </div>
    );
};

export default LoadingIndicator;
