import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import { useFilterModifiers } from 'hooks';

const { Option } = Select;

const Modifiers = (props) => {
    const { config } = useFilterModifiers();
    const { availableModifiers, fieldName } = props;

    if (availableModifiers.length === 0) {
        return false;
    }

    const modifierOptions = availableModifiers
        .map((modifier, index) => (
            <Option
                object={ config[modifier].value }
                value={ modifier }
                title={ config[modifier].text }
                key={ modifier }>
                { config[modifier].text }
            </Option>
        ));

    return (
        <Form.Item
            className="FilterModifier"
            label={ null }
            name={ `config[${fieldName}]` }
        >
            <Select defaultValue={ availableModifiers[0] } popupMatchSelectWidth={ false }>
                { modifierOptions }
            </Select>
        </Form.Item>
    );
};

Modifiers.propTypes = {
    availableModifiers: PropTypes.array,
    fieldName: PropTypes.string.isRequired,
};

Modifiers.defaultProps = {
    availableModifiers: [],
};

export default Modifiers;
