import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedRoot, useClub } from 'hooks';
import { HTTP, Response } from 'services';

const useDashboard = () => {
    const { data: authenticatedRootData, dataUpdatedAt: authenticatedRootDataUpdatedAt } = useAuthenticatedRoot();
    const { data: clubData } = useClub();
    const queryClient = useQueryClient();

    const { data, isLoading } = useQuery({
        queryKey: ['dashboard', authenticatedRootDataUpdatedAt],
        queryFn: async () => {
            let response = await HTTP
                .get(
                    Response.getLink(authenticatedRootData, 'dashboard'),
                    {
                        club: clubData?.id,
                    },
                )
                .then((res) => res.data);

            await queryClient.removeQueries({ queryKey: ['dashboard'], type: 'inactive' });

            return response;
        },
        enabled: !!authenticatedRootData,
    });

    return {
        data,
        isLoading,
    };
};

export default useDashboard;
