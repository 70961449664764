import React from 'react';
import { Button, Form, Input, Space } from 'antd';
import { Icon } from 'components/elements';
import { useAuth, useFormConfig, useFormRules } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { JWT } from 'services';

const defaultFormValues = {
    password: '',
    tokenString: '',
};

const UpgradeUser = () => {
    const { t: translate } = useTranslation();
    const { actionsConfig, config, label } = useFormConfig();
    const formRules = useFormRules();
    const { upgradeUser } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();

    if (false === JWT.isValid(searchParams.get('token'))) {
        return (
            <>
                <div className="text-center margin-top-medium margin-bottom-medium">
                    { translate('application.preauth.descriptions.verify_account_token_expired') }
                </div>

                <Link to="/forgotten-password">{ translate('application.preauth.links.forgotten_password') }</Link>
            </>
        );
    }

    const token = JWT.decode(searchParams.get('token'));

    return (
        <>
            <div className="text-center margin-top-medium margin-bottom-medium">
                { translate('application.preauth.descriptions.upgrade_user') }
            </div>

            <Form { ...config() }
                initialValues={ defaultFormValues }
                onFinish={ (values) => {
                    upgradeUser({ token: searchParams.get('token'), ...values });
                } }
            >
                {
                    true === token.requiresPassword ? (
                        <Form.Item name="password"
                            label={ label('form.fields.password') }
                            rules={ [formRules.PASSWORD_REQUIRED, formRules.PASSWORD_LENGTH, formRules.PASSWORD_COMPLEXITY] }
                        >
                            <Input.Password prefix={ <Icon name="Lock" /> }
                                autoComplete="current-password"
                                placeholder={ translate('form.fields.password') }
                            />
                        </Form.Item>
                    ) : null
                }

                <Form.Item { ...actionsConfig() }>
                    <Space direction="vertical">
                        <Button type="primary"
                            htmlType="submit"
                            icon={ <Icon name="ChevronsUp" /> }
                        >
                            { translate('form.buttons.upgrade_user') }
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </>
    );
};

export default UpgradeUser;
