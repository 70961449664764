import React from 'react';
import { Button, Form, Input, Space } from 'antd';
import { Icon } from 'components/elements';
import { useAuth, useFormConfig, useFormRules } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const defaultFormValues = {
    forename: '',
    surname: '',
    emailAddress: '',
};

const Register = () => {
    const { t: translate } = useTranslation();
    const { actionsConfig, config, label } = useFormConfig();
    const formRules = useFormRules();
    const { register } = useAuth();

    return (
        <Form { ...config() }
            initialValues={ defaultFormValues }
            onFinish={ (values) => register({ ...values }) }
        >
            <Form.Item className="form-item-two"
                label={ label('form.fields.name') }
                htmlFor="forename"
                required
            >

                <Form.Item name="forename"
                    className=""
                    rules={ [formRules.FORENAME_REQUIRED, formRules.FORENAME_LENGTH] }
                >
                    <Input prefix={ <Icon name="User" /> }
                        placeholder={ translate('form.fields.forename') }
                    />
                </Form.Item>

                <Form.Item name="surname"
                    className=""
                    rules={ [formRules.SURNAME_REQUIRED, formRules.SURNAME_LENGTH] }
                >
                    <Input prefix={ <Icon name="User" /> }
                        placeholder={ translate('form.fields.surname') }
                    />
                </Form.Item>

            </Form.Item>

            <Form.Item name="emailAddress"
                label={ label('form.fields.emailAddress') }
                rules={ [formRules.EMAIL_REQUIRED] }
            >
                <Input prefix={ <Icon name="AtSign" /> }
                    placeholder={ translate('form.fields.emailAddress') }
                />
            </Form.Item>

            <Form.Item { ...actionsConfig() }>
                <Space direction="vertical">
                    <Button type="primary"
                        htmlType="submit"
                        icon={ <Icon name="Send" /> }
                    >
                        { translate('form.buttons.register') }
                    </Button>

                    <Link to="/forgotten-password">{ translate('application.preauth.links.forgotten_password') }</Link>
                    <Link to="/login">{ translate('application.preauth.links.already_registered') }</Link>
                </Space>
            </Form.Item>
        </Form>
    );
};

export default Register;
