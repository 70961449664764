import club from "config/lang/en/form/rules/club";

const e = {
    rules: {
        name: {
            required: 'Please enter a name.',
        },
        forename: {
            required: 'Please enter a forename.',
            minLength: 'This value is too short. It should have 2 characters or more.',
        },
        surname: {
            required: 'Please enter a surname.',
            minLength: 'This value is too short. It should have 2 characters or more.',
        },
        emailAddress: {
            required: 'Please enter a valid email address.',
        },
        password: {
            required: 'Please enter your password.',
            length: 'Password must be at least 8 characters long.',
            complexity: 'Password must contain at least one lowercase letter, one uppercase letter, and one number.',
        },
        ...club,
    },
};

export default e;
