const e = {
    club: {
        name: {
            required: 'Please enter your club\'s name.',
        },
        address: {
            required: 'Please enter your club\'s address.',
        },
        town: {
            required: 'Please enter your club\'s town.',
        },
        postcode: {
            required: 'Please enter your club\'s postcode.',
        },
        country: {
            required: 'Please enter your club\'s country.',
        },
        membershipType: {
            name: {
                required: 'Please enter a name for the membership type.',
                length: 'Name must be at least two characters.',
            },
            frequency: {
                required: 'Please select a membership billing frequency.',
            },
            joiningFee: {
                required: 'Please enter a joining fee. Enter 0 if you would not like a joining fee.',
            },
            amount: {
                required: 'Please enter a billing amount. Enter 0 if you would not like to bill.',
            },
        },
    },
};

export default e;
