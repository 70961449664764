import React, { useState, useEffect } from 'react';
import { LoadingIndicator, StatusPending } from 'components/elements';
import { Loading } from 'components/wrappers';
import { useStripeOnboarding, useWindow } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';


const ClubSetupPaymentGatewayStripe = () => {
    const { data, validate } = useStripeOnboarding();
    const { t: translate } = useTranslation();
    const { open } = useWindow();
    const [ state, setState ] = useState('fetchingRedirectionUrl');

    const states = {
        fetchingRedirectionUrl: { showLoading: true, isError: false, showReloadPage: false, redirectTo: null },
        error: { showLoading: false, isError: true, showReloadPage: true, redirectTo: null },
        validating: { showLoading: true, isError: false, showReloadPage: false, redirectTo: null },
        validationError: { showLoading: false, isError: true, showReloadPage: true, redirectTo: null },
        validated: { showLoading: false, isError: false, showReloadPage: false, redirectTo: 'dashboard' },
        alreadyValidated: { showLoading: false, isError: false, showReloadPage: false, redirectTo: 'dashboard' },
        waitingForResponse: { showLoading: true, isError: false, showReloadPage: false, redirectTo: null },
    };

    /** @todo ...multiple validate triggers */
    useEffect(() => {
        if (data) {
            if (true === data?.isValidated) {
                setState('alreadyValidated');

                return;
            }

            if (null === data?.redirectionUrl) {
                /** @todo show error */
                return;
            }

            open(
                data?.redirectionUrl,
                async (e) => {
                    console.log('Handling');
                    if (false === e.data) {
                        setState('error');

                        return;
                    }

                    setState('validating');

                    let validationResponse = await validate();
                    if (false === validationResponse) {
                        setState('validationError');

                        return;
                    }

                    setState('validated');
                },
                'stripe_onboarding_window',
            );

            setState('waitingForResponse');
        }
    }, [data]);

    return (
        <div className="CenteredContainer">
            <div className="Box">
                <Loading isLoading={ !data }>
                    <h3 className="text-center margin-top-medium margin-bottom-medium multiline">
                        {
                            true === states[state].showLoading ?
                                <StatusPending text={ translate(`club.setup.paymentGateway.stripe.${state}`) } /> :
                                <>{ translate(`club.setup.paymentGateway.stripe.${state}`) }</>
                        }
                    </h3>

                    {
                        true === states[state].showLoading ?
                            <LoadingIndicator /> :
                            null
                    }

                    {
                        true === states[state].showReloadPage ?
                            <>{ translate('club.setup.paymentGateway.stripe.reload') }</> :
                            null
                    }

                    {
                        null !== states[state].redirectTo ?
                            (
                                <>
                                    <StatusPending text="Redirecting" />
                                    <Navigate to={ states[state].redirectTo } />
                                </>
                            ) :
                            null
                    }
                </Loading>
            </div>
        </div>
    );
};

export default ClubSetupPaymentGatewayStripe;
