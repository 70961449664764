import React from 'react';
import { icons } from 'lucide-react';
import PropTypes from 'prop-types';

const Icon = ({ name, color, size, ...others }) => {
    const LucideIcon = icons[name];

    return <LucideIcon color={ color } size={ size } { ...others } />;
};

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    color: (props, propName, componentName) => {
        if (false === /^#[0-9a-fA-F]{6}$/.test(props[propName])) {
            return new Error('Invalid prop `' + propName + '` supplied to' + ' `' + componentName + '`. Must be a valid color code.');
        }
    },
    size: PropTypes.oneOf([50, '50', 34, '34', 24, '24', 20, '20', 16, '16']),
};

Icon.defaultProps = {
    color: '#00a7ae',
    size: '16',
};

export default Icon;
