import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components/elements';
import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';

const NoResultsFound = () =>
{
    const { t: translate } = useTranslation();

    return <Flex vertical justify="center" align="center">
        <Icon name="BookDashed" size={ 50 } />
        <br />
        { translate('application.no_results_found') }
    </Flex>;
};

export default NoResultsFound;
