import { useTranslation } from 'react-i18next';

const useFilterModifiers = () => {
    const { t: translate } = useTranslation();

    const config = {
        contains: {
            text: translate('components.filter.modifiers.contains'),
            value: {type: 'contains', isInverse: false},
        },
        doesNotContain: {
            text: translate('components.filter.modifiers.does_not_contain'),
            value: {type: 'contains', isInverse: true},
        },
        beginsWith: {
            text: translate('components.filter.modifiers.begins_with'),
        },
        endsWith: {
            text: translate('components.filter.modifiers.ends_with'),
        },
        isEquals: {
            text: translate('components.filter.modifiers.is_equals'),
            value: {type: 'isEquals', isInverse: false},
        },
        isNotEquals: {
            text: translate('components.filter.modifiers.is_not_equal'),
            value: {type: 'isEquals', isInverse: true},
        },
    };

    return {
        config,
    };
};

export default useFilterModifiers;
