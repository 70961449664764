import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

let stateFilters = {};

const useFilters = (filterKey, filters) => {
    const queryClient = useQueryClient();
    const [searchParams, setSearchParams] = useSearchParams();

    const { data } = useQuery({
        queryKey: ['filter', filterKey, stateFilters[filterKey]],
        queryFn: () => {
            return stateFilters[filterKey] || filters || {};
        },
        gcTime: 5000,
        staleTime: 5000,
    });

    const { mutate: update } = useMutation({
        mutationFn: (filters) => {},
        onMutate: async (filters) => {
            stateFilters[filterKey] = filters;
            setSearchParams(filters);
        },
        onSettled: () => {
            queryClient.removeQueries({ queryKey: ['filter', filterKey], type: 'inactive' });
        },
    });

    const sort = (orderBy, order) => {
        let newFilterData = {
            ...data,
        };

        if (false === order) {
            newFilterData.order = '-'+orderBy;
        } else if (true === order) {
            newFilterData.order = orderBy;
        } else {
            delete newFilterData.order;
        }

        update(newFilterData);
    };

    const page = (page) => {

        update({...data, page});
    };

    return {
        data,
        update,
        page,
        sort,
    };
};

export default useFilters;
