import React from 'react';
import PropTypes from 'prop-types';

import LoadingIndicator from 'components/elements/LoadingIndicator';

const Loading = ({ isLoading, children, size, ...rest }) =>
{
    return (
        isLoading ?
            <LoadingIndicator size={ size || 'large' } { ...rest } /> :
            children
    );
};

Loading.propTypes = {
    isLoading: PropTypes.bool.isRequired,
};

Loading.defaultProps = {
    isLoading: true,
};

export default Loading;
