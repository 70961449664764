import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useClub, useFormConfig, useMobileDetection, useUserMembershipTypes } from 'hooks';
import { AddButton, Currency, Filters, Table, ViewButton } from 'components/elements';
import { defaultProps } from 'config/shapes/collection';
import { CardList, NoClubSelected } from 'components/elements';
import { Loading, Page } from 'components/wrappers';
import { Response } from 'services';
import { useTranslation } from 'react-i18next';

const MembershipTypeList = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { data: userMembershipTypesData, isLoading: userMembersIsLoading, filterData, page, sort, updateFilters } = useUserMembershipTypes(Object.fromEntries(searchParams));
    const { label } = useFormConfig();
    const { t: translate } = useTranslation();
    const { isMobile } = useMobileDetection();
    const { data: clubData } = useClub();

    // if (typeof clubData === 'undefined') {
    //     return <NoClubSelected />;
    // }

    const sorter = (pagination, filters, sorter, extra) => 'sort' === extra.action &&
        sort(sorter.field, {'ascend': true, 'descend': false, undefined}[sorter.order]);

    const dataPresenter = isMobile
        ? MembershipTypeListMobile({ userMembershipTypesData, userMembersIsLoading, translate, sorter, page })
        : MembershipTypeListStandard({ userMembershipTypesData, userMembersIsLoading, translate, sorter, page, });

    return (
        <Page>
            <Filters onSubmit={ updateFilters } defaultFilters={ Object.fromEntries(searchParams) }>
                <Filters.Input label={ label('form.fields.name') } name="name" availableModifiers={ ['contains'] } />
                <Filters.Select name="isActive" label={ label('form.fields.active') } options={ [{value: 'true', label: 'Active'}, {value: 'false', label: 'Inactive'}] } />
            </Filters>

            <AddButton
                link={ Response.getLinkResource(clubData, 'create-membership-type') }
                align={ isMobile ? null : 'end' }
            />

            { dataPresenter }
        </Page>
    );
};

const MembershipTypeListMobile = ({ userMembershipTypesData, userMembersIsLoading, translate, sorter, page }) => {
    return <CardList
        title={ translate('application.club.membershipTypes') }
        isLoading={ userMembersIsLoading }
        collection={ userMembershipTypesData ?? defaultProps }
        dataSource={ userMembershipTypesData?.membershipTypes ?? [] }
        columns={
            [
                {
                    label: translate('form.fields.name'),
                    dataIndex: 'name',
                },
                {
                    label: translate('form.fields.club.membershipType.frequency'),
                    dataIndex: 'frequency',
                    renderer: (value, item) => translate('application.club.membershipType.frequency.' + item.frequency),
                },
                {
                    label: translate('form.fields.club.membershipType.amount'),
                    dataIndex: 'amount',
                    renderer: (value, item) => <Currency value={ value } />,
                },
                {
                    label: translate('form.fields.club.membershipType.joiningFee'),
                    dataIndex: 'joiningFee',
                    renderer: (value, item) => <Currency value={ value } />,
                },
                {
                    type: 'action',
                    label: (item) => <ViewButton route={ Response.getBase64Link(item, 'self') } block/>,
                },
            ]
        }
    />
};

const MembershipTypeListStandard = ({ userMembershipTypesData, userMembersIsLoading, translate, sorter, page }) => {
    let columns = [
        {
            title: translate('form.fields.club.membershipType.name'),
            key: 'name',
            dataIndex: 'name',
            width: '40%',
            sorter: true,
        },
        {
            title: translate('form.fields.club.membershipType.frequency'),
            key: 'frequency',
            render: (text, record) => translate('application.club.membershipType.frequency.'+record.frequency),
            width: '20%',
        },
        {
            title: translate('form.fields.club.membershipType.amount'),
            key: 'amount',
            dataIndex: 'amount',
            render: (text, record) => <Currency value={ record.amount } />,
            width: '20%',
            sorter: true,
        },
        {
            title: translate('form.fields.club.membershipType.joiningFee'),
            key: 'joiningFee',
            dataIndex: 'joiningFee',
            render: (text, record) => <Currency value={ record.joiningFee } />,
            width: '20%',
            sorter: true,
        },
        {
            title: '',
            key: 'view',
            render: (text, record) => <ViewButton route={ btoa(Response.getLink(record, 'self')) } />,
            fixed: 'right',
            width: '100px',
        }
    ];

    return <Table
        title={ translate('application.club.membershipTypes') }
        collection={ userMembershipTypesData ?? defaultProps }
        columns={ columns }
        dataSource={ userMembershipTypesData?.membershipTypes ?? [] }
        isLoading={ userMembersIsLoading }
        onChange={ sorter }
        onPage={ (pageObject) => {
            console.log(pageObject)
            page(pageObject.page)
        } }
    />;
};

export default MembershipTypeList;
