import i18n from 'i18next';

const { t: translate } = i18n;

const e = {
    membershipType: {
        NAME_REQUIRED: {
            required: true,
            type: 'string',
            message: translate('form.rules.club.membershipType.name.required'),
        },
        NAME_LENGTH: {
            min: 2,
            message: translate('form.rules.club.membershipType.name.length'),
        },
        FREQUENCY_REQUIRED: {
            required: true,
            type: 'string',
            message: translate('form.rules.club.membershipType.frequency.required'),
        },
        JOINING_FEE_REQUIRED: {
            required: true,
            type: 'string',
            min: 0,
            message: translate('form.rules.club.membershipType.joiningFee.required'),
        },
        AMOUNT_REQUIRED: {
            required: true,
            type: 'string',
            message: translate('form.rules.club.membershipType.amount.required'),
        },
    },
};

export default e;
