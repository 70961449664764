import React from 'react';
import PropTypes from 'prop-types';
import { Table as AntTable, Typography } from 'antd';
import { merge } from 'lodash';
import collection from 'config/shapes/collection';
import { Response } from 'services';

const { Title } = Typography;

const Table = ({ collection, columns, config, dataSource, filters, isLoading, onPage, title, ...rest }) => {
    const mergedConfig = merge({}, Table.defaultProps.config, config);

    let reducedColumns = columns
        .filter((item) => {
            if (typeof item.display === 'undefined') {
                return true;
            }

            return item.display;
        });

    const pagination = false === mergedConfig.pagination ?
        false :
        merge(
            {},
            mergedConfig.pagination,
            {
                current: +new URLSearchParams(Response.getLink(collection, 'self')).get('page') || 1,
                pageSize: (typeof collection === 'object' &&
                    typeof collection.limit === 'number') ?
                    collection.limit :
                    dataSource.length,
                total: collection?.count || 0,
                onChange: (page, pageSize) => {
                    onPage?.(
                        Object.assign(
                            {},
                            filters,
                            {page}
                        )
                    );
                }
            }
        );

    return (
        <AntTable
            { ...rest }
            dataSource={
                dataSource.length > 0 ?
                    dataSource.map((value, key) => {
                        return Object.assign(
                            {},
                            {key: key},
                            value
                        );
                    }) :
                    null
            }
            columns={ reducedColumns }
            pagination={ pagination }
            hasData={ dataSource.length > 0 }
            loading={ isLoading }
            sticky={ mergedConfig.hasStickyHeaders }
            size={ mergedConfig.size }
            total={ collection.count }
            scroll={ mergedConfig.scroll }
            rowClassName={ (record, index) => {
                return index % 2 !== 0 ? 'table-alternate-row' : null;
            } }
            title={
                (pageData) => {
                    if (typeof title === 'undefined') {
                        return;
                    }

                    return (
                        <Title level={ 3 }>
                            { title }
                            {
                                mergedConfig.showTitleCount ?
                                    '   (' + (collection?.count || dataSource?.length || 0) + ')' :
                                    null
                            }
                        </Title>
                    );
                }
            } />
    );
};

Table.propTypes = {
    collection: collection.propTypes,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    config: PropTypes.shape({
        hasStickyHeaders: PropTypes.bool,
        pagination: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.shape({
                position: PropTypes.arrayOf(PropTypes.string),
            }),
        ]),
        scroll: PropTypes.object,
        size: PropTypes.string,
        showTitleCount: PropTypes.bool,
    }),
    dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool,
};

Table.defaultProps = {
    collection: collection.defaultProps,
    config: {
        hasStickyHeaders: true,
        pagination: {
            position: ['bottomCenter'],

        },
        scroll: {x: 600},
        size: 'small',
        showTitleCount: true,
    },
    isLoading: true,
};

export default Table;
