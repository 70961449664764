import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useClub, useFilters, useUser } from 'hooks';
import { HTTP, Response } from 'services';

const useUserMembershipTypes = (filters = {}) => {
    const { data: filterData, update: updateFilters, page, sort, } = useFilters('user-membership-types', filters);
    const { data: userData } = useUser();
    const { data: clubData } = useClub();

    const { data, isLoading } = useQuery({
        queryKey: ['user', 'membership-types', Response.getLink(userData, 'membership-types'), { ...filterData, club: clubData?.id }],
        queryFn: async () => {
            return await HTTP
                .get(Response.getLink(userData, 'membership-types'), { ...filterData, club: clubData?.id })
                .then(res => res && res.data) || [];
        },
        enabled: !!clubData,
        gcTime: 5000,
        staleTime: 5000,
    });

    return {
        data,
        isLoading,

        filterData,
        updateFilters,
        page,
        sort,
    };
};

export default useUserMembershipTypes;
