import React from 'react';
import LogoStamp from 'assets/Full Logo.png';
import { Icon } from 'components/elements';
import ForgottenPassword from 'components/ForgottenPassword';
import ForgottenPasswordPending from 'components/ForgottenPasswordPending';
import Login from 'components/Login';
import Pending from 'components/Pending';
import Register from 'components/Register';
import ResetPassword from 'components/ResetPassword';
import UpgradeUser from 'components/UpgradeUser';
import VerifyAccount from 'components/VerifyAccount';
import { Navigate, Route, Routes } from 'react-router-dom';

const PreAuthContainer = () => {
    return (
        <div className="PreAuthContainer">
            <div className="Box">
                <img src={ LogoStamp } />

                <Routes>
                    <Route path="/login" element={ <Login /> } exact />
                    <Route path="/forgotten-password" element={ <ForgottenPassword /> } exact />
                    <Route path="/forgotten-password-pending" element={ <ForgottenPasswordPending /> } exact />
                    <Route path="/reset-password" element={ <ResetPassword /> } exact />
                    <Route path="/register" element={ <Register /> } exact />
                    <Route path="/pending" element={ <Pending /> } exact />
                    <Route path="/verify" element={ <VerifyAccount /> } exact />
                    <Route path="/upgrade" element={ <UpgradeUser /> } exact />

                    <Route path="*" element={ <Navigate to="/login" /> } />
                </Routes>

                <footer>
                    <span>
                        <a href="tel:+03300000000"><Icon name="Phone" color="#ffffff" /> 0330 000 0000</a>
                    </span>

                    <span>
                        <a href="mailto:a@b.com"><Icon name="Mail" color="#ffffff" /> Contact Us</a>
                    </span>
                </footer>
            </div>
        </div>
    );
};

export default PreAuthContainer;
