import React from 'react';
import { Button, Form, Input, Space } from 'antd';
import { Icon } from 'components/elements';
import { useAuth, useFormConfig, useFormRules } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { JWT } from 'services';

const defaultFormValues = {
    password: '',
    tokenString: '',
};

const VerifyAccount = () => {
    const { t: translate } = useTranslation();
    const { actionsConfig, config, label } = useFormConfig();
    const formRules = useFormRules();
    const { verify } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();

    if (false === JWT.isValid(searchParams.get('token'))) {
        return (
            <>
                <div className="text-center margin-top-medium margin-bottom-medium">
                    { translate('application.preauth.descriptions.verify_account_token_expired') }
                </div>

                <Link to="/forgotten-password">{ translate('application.preauth.links.forgotten_password') }</Link>
            </>
        );
    }

    return (
        <>
            <div className="text-center margin-top-medium margin-bottom-medium">
                { translate('application.preauth.descriptions.verify_account') }
            </div>

            <Form { ...config() }
                initialValues={ defaultFormValues }
                onFinish={ (values) => {
                    verify({ token: searchParams.get('token'), ...values });
                } }
            >
                <Form.Item name="password"
                    label={ label('form.fields.password') }
                    rules={ [formRules.PASSWORD_REQUIRED, formRules.PASSWORD_LENGTH, formRules.PASSWORD_COMPLEXITY] }
                >
                    <Input.Password prefix={ <Icon name="Lock" /> }
                        autoComplete="current-password"
                        placeholder={ translate('form.fields.password') }
                    />
                </Form.Item>

                <Form.Item { ...actionsConfig() }>
                    <Space direction="vertical">
                        <Button type="primary"
                            htmlType="submit"
                            icon={ <Icon name="CheckSquare" /> }
                        >
                            { translate('form.buttons.verify_account') }
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </>
    );
};

export default VerifyAccount;
