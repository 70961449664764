import * as jose from 'jose';

const JWT = {
    decode: jose.decodeJwt,
    sign: jose.SignJWT,
    verify: jose.jwtVerify,

    isValid: (token) => {
        try {
            const decodedToken = jose.decodeJwt(token),
                unixTimestamp = +new Date()/1000;

            return !!(decodedToken &&
                (!decodedToken.iat || decodedToken.iat <= unixTimestamp) &&
                (!decodedToken.exp || decodedToken.exp > unixTimestamp));
        } catch (e) {
            return false;
        }
    },
};

export default JWT;
