import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormConfig, useMobileDetection } from 'hooks';
import { Button, Col, Collapse, Form, Row, Space } from 'antd';
import { Icon } from 'components/elements';
import { useTranslation } from 'react-i18next';
import debounce from 'debounce';
import { Input, Select } from 'components/elements/filter';

const { Panel } = Collapse;

const Filters = ({ autoSubmit, debounceDelay, defaultFilters, filtersPerRow, isOpen, onSubmit, ...props }) => {
    const { isMobile } = useMobileDetection();
    const { actionsConfig, config, label } = useFormConfig();
    const { t: translate } = useTranslation();
    const [form] = Form.useForm();

    const children = React.Children.toArray(props.children);

    var filterDebounce,
        filterDebounceDelay = autoSubmit ? debounceDelay : 0;

    const calculatedFiltersPerRow = isMobile ? 1 : filtersPerRow;

    const handleSubmit = (values) => {
        if (filterDebounce) {
            filterDebounce.clear();
        }

        (filterDebounce = debounce(() => {
            let filterValues = {};

            Object
                .keys(values)
                .forEach(key => {
                    let value = values[key];

                    if ([null, undefined].indexOf(value) !== -1) {
                        return;
                    }

                    filterValues[key] = value;
                });

            onSubmit(filterValues);
        }, filterDebounceDelay))();
    };

    const handleClear = async () => {
        handleSubmit({});
        // form.resetFields() doesn't appear to fire consistently
        Object.entries(form.getFieldsValue(true))
            .forEach(([key, value], index) => {
                form.setFieldValue(key, null);
            });
    };

    const getWrappedChildren = useCallback(() =>
    {
        const filterGroups = [];

        children
            .forEach((item, index) => {
                if (index % calculatedFiltersPerRow === 0) {
                    filterGroups.push([]);
                }

                filterGroups[filterGroups.length - 1]
                    .push(
                        <Col span={ Math.floor(24 / calculatedFiltersPerRow) } key={ index } >
                            { item }
                        </Col>
                    );

            });

        return filterGroups
            .map((filters, index) => {
                return (
                    <Row key={ index }>
                        { filters }
                    </Row>
                );
            });
    }, [
        children,
        calculatedFiltersPerRow,
    ]);


    const submitButton = autoSubmit ?
        null :
        (
            <Form.Item { ...actionsConfig() }>
                <Space direction="horizontal">
                    <Button type="default"
                        onClick={ handleClear }
                        icon={ <Icon name="X" /> }
                    >
                        { translate('components.filter.buttons.clear') }
                    </Button>

                    <Button type="primary"
                        htmlType="submit"
                        icon={ <Icon name="Filter" /> }
                    >
                        { translate('components.filter.buttons.filter') }
                    </Button>
                </Space>
            </Form.Item>
        );

    return (
        <div className="Filters">
            <Collapse defaultActiveKey={ [ isOpen ? '1' : null ] }>
                <Panel header={ translate('components.filters') } key="1">
                    <Form { ...config() }
                        form={ form }
                        initialValues={ defaultFilters }
                        onFinish={ handleSubmit }
                        onValuesChange={ (changedValues, allValues) => {
                            autoSubmit && handleSubmit(allValues);
                        } }>
                        { getWrappedChildren() }

                        { submitButton }
                    </Form>
                </Panel>
            </Collapse>
        </div>
    );
};

Filters.propTypes = {
    autoSubmit: PropTypes.bool,
    debounceDelay: PropTypes.number,
    defaultFilters: PropTypes.object,
    filtersPerRow: PropTypes.number,
    isOpen: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
};

Filters.defaultProps = {
    autoSubmit: false,
    debounceDelay: 500,
    defaultFilters: {},
    filtersPerRow: 2,
    isOpen: false,
};

Filters.Input = Input;
Filters.Select = Select;

export default Filters;
