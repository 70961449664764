class Response {
    static addLink(
        object,
        linkName,
        link,
    ) {
        if (!Object.prototype.hasOwnProperty.call(object, '_links')) {
            object._links = {};
        }

        object._links[linkName] = {href: link};

        return object;
    }

    static addCollectionLink(
        object,
        linkName,
        link,
    ) {
        if (!Object.prototype.hasOwnProperty.call(object, '_links')) {
            object._links = {};
        }

        if (!Object.prototype.hasOwnProperty.call(object._links, linkName)) {
            object._links[linkName] = [];
        }

        object._links[linkName].push({href: link});

        return object;
    }

    static getLinkResource(
        response,
        link,
    ) {
        if (response &&
            response._links &&
            response._links[link]) {
            return response._links[link];
        }

        return null;
    }

    static getBase64Link(
        response,
        link,
    ) {
        const linkString = Response.getLink(response, link);

        return linkString
            ? btoa(linkString)
            : null;
    }

    static getLink(
        response,
        link,
    ) {
        return Response.getLinkAttribute(response, link, 'href');
    }

    static getLinkWithQueryString(
        response,
        link,
        queryParams,
    ) {
        const queryString = new URLSearchParams(queryParams).toString();

        return `${Response.getLink(response, link)}?${queryString}`;

    }

    static getLinkAttributes(
        response,
        link,
        attributes = [],
    ) {
        let returnAttributes = [];
        attributes.forEach((attribute) => {
            let value = Response.getLinkAttribute(response, link, attribute);
            if (value !== null) {
                returnAttributes.push(value);
            }
        });

        return returnAttributes;
    }

    static getLinkAttribute(
        response,
        link,
        attribute,
    ) {
        if (response &&
            response._links &&
            response._links[link] &&
            typeof response._links[link][attribute] !== 'undefined') {
            return response._links[link][attribute];
        }

        return null;
    }

    static getUpdatableResponse(response) {
        return Object.assign({}, response, {onUpdate: function(property, value) {
            this[property] = value;

            return this;
        }});
    }

    static getEmbeddedObject(
        response,
        objectName,
    ) {
        if (response &&
            response._embedded &&
            response._embedded[objectName]) {
            return response._embedded[objectName];
        }

        return null;
    }
}

export default Response;
