import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useClub, useUser } from 'hooks';
import { HTTP, Response } from 'services';

const useClubs = () => {
    const queryClient = useQueryClient();
    const { data: userData } = useUser();
    const { select } = useClub();

    const { data } = useQuery({
        queryKey: ['clubs', Response.getLink(userData, 'clubs')],
        queryFn: async () => {
            let response = await HTTP
                .get(Response.getLink(userData, 'clubs'))
                .then(res => res && res.data);

            if (response.clubs?.length === 1) {
                select(Response.getLink(response.clubs[0], 'self'));
            }

            await queryClient.removeQueries({ queryKey: ['clubs'], type: 'inactive' });

            return response || [];
        },
        enabled: !!userData,
        defaultValue: [],
    });

    return {
        data,
    };
};

export default useClubs;
