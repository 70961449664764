const e = {
    filters: 'Filters',
    filter: {
        buttons: {
            filter: 'Filter',
            clear: 'Clear',
        },
        modifiers: {
            contains: 'Contains',
            does_not_contain: 'Does Not Contain',
            begins_with: 'Begins With',
            ends_with: 'Ends With',
            is_equals: 'Is Equal To',
            is_not_equal: 'Is Not Equal To',
        },
    },
};

export default e;
