import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useUser, useFilters } from 'hooks';
import { HTTP, Response } from 'services';

const useUserMembers = () => {
    const queryClient = useQueryClient();
    const { data: filterData, update: updateFilters } = useFilters('user-members', {});
    const { data: userData } = useUser();

    const { data, isLoading } = useQuery({
        queryKey: ['user', 'members', Response.getLink(userData, 'members'), {...filterData}],
        queryFn: async () => {
            let response = await HTTP
                .get(Response.getLink(userData, 'members'), filterData)
                .then(res => res && res.data);

            return response || [];
        },
        gcTime: 5000,
        staleTime: 5000,
        enabled: !!userData,
    });

    return {
        data,
        isLoading,

        updateFilters,
    };
};

export default useUserMembers;
