import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useAuthenticatedRoot } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { HTTP, Response } from 'services';

const useClub = () => {
    const queryClient = useQueryClient();
    const { data: authenticatedRootData } = useAuthenticatedRoot();
    const navigate = useNavigate();

    const { data, isLoading } = useQuery({
        queryKey: ['club', localStorage.getItem('club.route')],
        queryFn: async () => {
            const route = localStorage.getItem('club.route');

            if (typeof route === 'undefined') {
                return null;
            }

            return await HTTP
                .get(localStorage.getItem('club.route'))
                .then((res) => res.data);
        },
    });

    const { mutate: select } = useMutation({
        mutationFn: (route) => {
            localStorage.setItem('club.route', route);
        },
        onSettled: () => {
            queryClient.refetchQueries({ queryKey: ['club'] });
        },
    });

    const { mutate: create, isPending: createPending } = useMutation({
        mutationFn: ({ name, address }) => {
            return HTTP
                .post(
                    Response.getLink(authenticatedRootData, 'create-club'),
                    {
                        name,
                        address,
                    },
                );
        },
        onSettled: async (res) => {
            if (res) {
                select(Response.getLink(res.data, 'self'));
                await queryClient.refetchQueries({ queryKey: ['authenticatedRoot'] });
                await queryClient.refetchQueries({ queryKey: ['clubs'] });
                await queryClient.refetchQueries({ queryKey: ['dashboard'] });

                return navigate('/setup/club/membership-type');
            }

            return false;
        },
    });

    const clear = () => {
        localStorage.removeItem('club.route');
        queryClient.resetQueries({ queryKey: ['club'] });
    };

    return {
        data,
        isLoading,

        create,
        createPending,
        select,
        clear,
    };
};

export default useClub;
