import { notification } from 'antd';
import axios from 'axios';
import { useAuth } from 'hooks';
import { useTranslation } from 'react-i18next';

let initialised = false;

const useAxios = () => {
    const { t: translate } = useTranslation();
    const { logout } = useAuth();

    const init = () => {
        if (false === initialised) {
            initialised = true;

            axios.interceptors
                .response
                .use(
                    (response) => {
                        if (response?.status >= 400 && response?.status <= 499) {
                            handleErrors(response);
                        }

                        return response;
                    },
                    (error) => {
                        handleErrors(error.response || error.data);
                    },
                );
        }
    };

    const handleErrors = (response) => {
        let errors = [];

        if (Array.isArray(response?.data?.errors)) {
            errors.push(...response.data.errors);
        } else if (response?.data?.message) {
            errors.push(response.data.message);
        } else if (typeof response?.data?.errors === 'object') {
            Object.entries(response.data.errors)
                .forEach((value) => {
                    if (Array.isArray(value)) {
                        errors.push(translate('form.fields.' + value[0]) + ': ' + value[1]);
                    }
                });
        }

        errors.forEach((error) => {
            if (error) {
                notification.error({ message: error });
            }
        });

        if ([401, 403].indexOf(response?.status) !== -1) {
            logout();
        }
    };

    return {
        init,
    };
};

export default useAxios;
