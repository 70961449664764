import React from 'react';
import { Button, Form, Input, Space } from 'antd';
import { Icon } from 'components/elements';
import { useAuth, useFormConfig, useFormRules } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

const defaultFormValues = {
    emailAddress: '',
};

const ForgottenPassword = () => {
    const { t: translate } = useTranslation();
    const { actionsConfig, config, label } = useFormConfig();
    const formRules = useFormRules();
    const { recoverPassword } = useAuth();
    const navigate = useNavigate();

    return (
        <Form { ...config() }
            initialValues={ defaultFormValues }
            onFinish={ recoverPassword }
        >
            <Form.Item name="emailAddress"
                label={ label('form.fields.emailAddress') }
                rules={ [formRules.EMAIL_REQUIRED] }
            >
                <Input prefix={ <Icon name="AtSign" /> }
                    placeholder={ translate('form.fields.emailAddress') }
                    data-testid="forgottenPassword-emailAddress"
                />
            </Form.Item>

            <Form.Item { ...actionsConfig() }>
                <Space direction="vertical">
                    <Button type="primary"
                        htmlType="submit"
                        icon={ <Icon name="ThumbsUp" /> }
                        data-testid="forgottenPassword-button"
                    >
                        { translate('form.buttons.recover_password') }
                    </Button>

                    <Link to="/login">{ translate('application.preauth.links.login_instead') }</Link>
                </Space>
            </Form.Item>
        </Form>
    );
};

export default ForgottenPassword;
